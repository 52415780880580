// LandingPage.js
import React, { useState, useRef } from 'react';
import './LandingPage.css'
import { Link } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Navbar from './Navbar';
import Footer from './Footer';
import api from '../api';
import Toast from '../UI/Toast';
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingButton from '../UI/LoadingButton';

const LandingPage = () => {

    const recaptchaRef = useRef();
    const [openIndex, setOpenIndex] = useState(null);
    const [toast, setToast] = useState({ show: false, type: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const togglePanel = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    const handleEbookSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const email = e.target.email.value;
            const response = await api.post('/ebook_optin', { email });

            if (response.data.status === 'success') {
                setToast({
                    show: true,
                    type: 'success',
                    message: response.data.message
                });
                e.target.reset();
            }
        } catch (error) {
            setToast({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'An error occurred. Please try again.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <ParallaxProvider>
                <Navbar />

                <Parallax className='parallax-bg' speed={-30}>
                    <img src="/background.png" alt="background image" className="parallax-image" />
                </Parallax>

                <Parallax className='landing-page'>

                    <div className="section hero-section">
                        <div className="hero-content">
                            <h1>Turn <span className="highlight">YouTube's Infinite Library</span> into Your Next Unbeatable Piece of Content</h1>
                            <h4>The AI-Powered Tool for Ghostwriters to Generate Unique, Plagiarism-Free Content in Seconds</h4>
                            <Link to="/free_trial" className="no-style-link">
                                <button className="button-important">
                                    <div className="inner">
                                        Create content now for FREE
                                    </div>
                                </button>
                            </Link>
                        </div>

                        <hr />

                        <div className="section styles-section">
                            <div className="section-header">
                                <h4>FORMATS</h4>
                                <h2>Transform YouTube Videos Into Any Format</h2>
                                <p>One video, endless possibilities. Generate various types of content with just a few clicks.</p>
                            </div>

                            <div className="styles-grid">
                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-newspaper"></i>
                                    </div>
                                    <h3>Blog Article</h3>
                                    <p>Convert videos into SEO-optimized blog posts with proper structure.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <h3>Twitter Thread</h3>
                                    <p>Transform long videos into engaging Twitter threads.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-envelope-open-text"></i>
                                    </div>
                                    <h3>Newsletter</h3>
                                    <p>Create email-ready content from any YouTube video.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fab fa-linkedin"></i>
                                    </div>
                                    <h3>LinkedIn Post</h3>
                                    <p>Generate professional LinkedIn content with perfect formatting.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-list-check"></i>
                                    </div>
                                    <h3>Summary</h3>
                                    <p>Create concise bullet-point summaries of any video.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-pen-fancy"></i>
                                    </div>
                                    <h3>Custom Format</h3>
                                    <p>Design your own format with our flexible template system.</p>
                                </div>
                            </div>
                        </div>

                        <video muted autoPlay loop src="/demo.mp4" className="hero-video glowAnime"></video>

                        <div className="trusted-by-section">
                            <div className="trusted-by-carousel">
                                <div className="carousel-container">
                                    <div className="carousel-track">
                                        {/* Premier groupe */}
                                        <div className="carousel-item">
                                            <a href="https://theresanaiforthat.com/ai/yt-copycat/?ref=featured&v=139162"
                                                target="_blank"
                                                rel="nofollow">
                                                <img
                                                    width="300"
                                                    src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
                                                    alt="Featured on There's An AI For That"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="section features-section" id="features">
                        <div className="section-header">
                            <h4>FEATURES</h4>
                            <h2>Transform <span className="highlight">YouTube Videos</span> into Endless Content</h2>
                            <p>
                                Embrace the Future of Content Creation with GPT-4's Magic and Multilingual Support
                            </p>
                        </div>

                        <div className="features-grid">
                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-wand-magic-sparkles"></i>
                                </div>
                                <div>
                                    <h4>AI-Powered Content</h4>
                                    <p>Transform video content into high-quality articles using GPT-4 technology, maintaining your unique voice.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-language"></i>
                                </div>
                                <div>
                                    <h4>Multilingual Support</h4>
                                    <p>Generate content in 10+ languages to reach a global audience with perfect native translations.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-bolt"></i>
                                </div>
                                <div>
                                    <h4>Lightning Fast</h4>
                                    <p>Get quality content in seconds, regardless of video length. Save hours of manual transcription.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-shield-halved"></i>
                                </div>
                                <div>
                                    <h4>100% Unique Content</h4>
                                    <p>Generate plagiarism-free, SEO-optimized content that ranks well on search engines.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-layer-group"></i>
                                </div>
                                <div>
                                    <h4>Multiple Formats</h4>
                                    <p>Create blog posts, Twitter threads, newsletters, and more from a single video source.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-sliders"></i>
                                </div>
                                <div>
                                    <h4>Advanced Customization</h4>
                                    <p>Fine-tune tone, style, and format to match your brand's voice perfectly.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section steps-section" id="steps">
                        <img src="/screenshot_1.png" alt="YT Copycat creativity" />
                        <div className="steps-content">
                            <div className='section-header'>
                                <h4>STEPS</h4>
                                <h2>
                                    How does <span className="highlight">YT Copycat</span> work?
                                </h2>
                            </div>
                            <div className="step">
                                <h3 className="step-number">01</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Enter URL</h4>
                                    <p className="step-description">Enter the YouTube video URL you want to convert.</p>
                                </div>
                            </div>
                            <div className="step">
                                <h3 className="step-number">02</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Let AI Work</h4>
                                    <p className="step-description">
                                        Let our tool do the work for you by extracting the content.
                                    </p>
                                </div>
                            </div>
                            <div className="step">
                                <h3 className="step-number">03</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Get Content</h4>
                                    <p className="step-description">
                                        Get your text content within seconds!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section reviews-section" id="reviews">
                        <div className="section-header">
                            <h2>What our <span className="highlight">users</span> say</h2>
                        </div>

                        <div className="reviews-grid">
                            <div className="review-item">
                                <div className="review-header">
                                    <span>Thomas K. ⭐⭐⭐⭐⭐</span>
                                </div>
                                <div className="review-content">
                                    "Game-changing tool! Tripled my content output while maintaining quality. The AI understands context perfectly."
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                    <span>Sarah L. ⭐⭐⭐⭐⭐</span>
                                </div>
                                <div className="review-content">
                                    "Managing multiple blogs became effortless. The multilingual generation is particularly impressive for my international audience."
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                    <span>Alex B. ⭐⭐⭐⭐⭐</span>
                                </div>
                                <div className="review-content">
                                    "Intuitive interface with mind-blowing results. The tone customization feature is exactly what I needed for my brand."
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                    <span>Emma M. ⭐⭐⭐⭐⭐</span>
                                </div>
                                <div className="review-content">
                                    "Perfect for scaling content creation. Saves me hours of work while maintaining consistent quality across all formats."
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="section faq-section" id="faq">
                        <div className="section-header">
                            <h2>Frequently Asked <span className="highlight">Questions</span></h2>
                        </div>

                        <div className="faq-grid">
                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(0)}>
                                    <div className="question-content">
                                        <i className="fas fa-robot"></i>
                                        <span>How does YT Copycat work?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 0 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 0 && (
                                    <div className="faq-answer">
                                        YT Copycat uses advanced AI to transcribe and analyze YouTube videos, then generates unique content tailored to your needs. Simply paste the video URL, choose your desired content type, and our AI handles the rest - from transcription to final polished content.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(1)}>
                                    <div className="question-content">
                                        <i className="fas fa-fingerprint"></i>
                                        <span>Is the generated content unique and SEO-friendly?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 1 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 1 && (
                                    <div className="faq-answer">
                                        Absolutely! All content is 100% unique and optimized for search engines. Our AI completely reformulates the content while preserving the core message. Each piece includes proper headings, meta descriptions, and keyword optimization for maximum SEO impact.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(2)}>
                                    <div className="question-content">
                                        <i className="fas fa-globe"></i>
                                        <span>What languages and content formats are supported?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 2 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 2 && (
                                    <div className="faq-answer">
                                        We support 10+ languages including English, French, Spanish, German, Italian, and Portuguese. Content can be generated in multiple formats: blog posts, social media threads, newsletters, video scripts, and product descriptions. Each format is optimized for its specific platform.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(3)}>
                                    <div className="question-content">
                                        <i className="fas fa-copyright"></i>
                                        <span>What about copyright and content attribution?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 3 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 3 && (
                                    <div className="faq-answer">
                                        We recommend following fair use guidelines and obtaining creator permission when necessary. Our system automatically includes source attribution and can be configured to match your citation requirements. The generated content is thoroughly rewritten to avoid copyright issues while maintaining proper attribution.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(4)}>
                                    <div className="question-content">
                                        <i className="fas fa-credit-card"></i>
                                        <span>How does pricing and word count work?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 4 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 4 && (
                                    <div className="faq-answer">
                                        Our pricing is based on monthly word count limits. Free users get 500 words/month, while premium plans offer up to 200,000 words/month. Unused words don't roll over, but you can upgrade or purchase additional words anytime. Word count is calculated based on the final generated content.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(5)}>
                                    <div className="question-content">
                                        <i className="fas fa-pen-fancy"></i>
                                        <span>Can I customize the AI's writing style?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 5 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 5 && (
                                    <div className="faq-answer">
                                        Yes! You can customize tone (professional, casual, academic), writing style, content structure, and even specific vocabulary preferences. Premium users can save multiple style presets for different content types or brands. The AI adapts to match your desired voice while maintaining readability and engagement.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}

                    <div className="section ebook-section">
                        <div className="ebook-container">
                            <div className="ebook-image">
                                <img src="/ebook.png" alt="Ebook preview" />
                            </div>
                            <div className="ebook-content">
                                <h2>Wait! We have something for you</h2>
                                <p>Unlock efficiency with "Productivity Unleashed". Dive into essential productivity principles and strategies to streamline your success. Claim yours and start mastering efficiency today.</p>
                                <form className="ebook-form" onSubmit={handleEbookSubmit}>
                                    <input
                                        type="email"
                                        placeholder="Your email address"
                                        name="email"
                                        required
                                    />
                                    <div className="recaptcha-container">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        />
                                        <div className="recaptcha-terms">
                                            This site is protected by reCAPTCHA and the Google
                                            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                        </div>
                                    </div>
                                    <LoadingButton
                                        type="submit"
                                        className="button-important"
                                        isLoading={isSubmitting}
                                    >
                                        <div className="inner">Get my book</div>
                                    </LoadingButton>
                                </form>
                            </div>
                        </div>
                    </div>



                    <Footer />
                </Parallax>
            </ParallaxProvider>

            {/* Toast en dehors du ParallaxProvider */}
            {toast.show && (
                <Toast
                    type={toast.type}
                    message={toast.message}
                    onClose={() => setToast({ ...toast, show: false })}
                    duration={6000}
                />
            )}
        </>
    );
};

export default LandingPage;
